<template>
  <div class="wrapper__lg">
    <div class="product__recommended__title">
      <h3>{{ props.title }}</h3>
    </div>
    <ProductSlider
      v-if="recommendedProducts.length > 0"
      :products="recommendedProducts"
      :options="recommendedProductsOptions"
      :customer="customer"
      source="recommended-api"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ProductSlider from './ProductSlider.vue';
import breakpoints from '../../scripts/breakpoints';

const props = defineProps({
  productId: {
    type: Number,
    required: true,
    default: () => 0,
  },
  title: {
    type: String,
    required: true,
    default: () => 'Recommended Products',
  },
  customer: {
    type: Object,
    required: true
  }
});

const recommendedProducts = ref([]);

const recommendedProductsOptions = {
  arrows: false,
  pagination: true,
  perPage: 2,
  perMove: 1,
  type: 'slide',
  gap: '10px',
  mediaQuery: 'min',
  breakpoints: {
    [breakpoints.px_breakpoints['--tablet-lg-up']]: {
      arrows: true,
      pagination: false,
      gap: '20px',
      perPage: 4,
    },
  },
};

onMounted(() => {
  fetch(`/recommendations/products.json?product_id=${props.productId}&limit=6`)
      .then((res) => res.json())
      .then(({ products }) => {
        recommendedProducts.value = products;
      })
});

</script>
