<template>
  <div v-if="!data.loading && data.variants.length">
    <section class="product__main">
      <div class="product__main__gallery">
        <div
          v-if="loggedIn && price"
          @click="triggerListModal = true"
          class="product__main__wishlist"
        >
          <IconWishlist />
          <p class="accent">Add to list</p>
        </div>
        <template v-if="media.length">
          <splide :options="productOptions" ref="productCarousel">
            <template v-for="(mediaObj, idx) in media" :key="idx">
              <splide-slide>
                <div class="product__main__gallery__image">
                  <v-img :media="mediaObj" />
                </div>
              </splide-slide>
            </template>
          </splide>
          <div  v-if="media.length > 1" class="splide__arrows">
            <button
              @click.prevent="prevSlide()"
              class="splide__arrow splide__arrow--prev"
            >
              <IconChevron />
            </button>
            <button
              @click.prevent="nextSlide()"
              class="splide__arrow splide__arrow--next"
            >
              <IconChevron />
            </button>
          </div>
        </template>
        <template v-else>
          <div class="product__main__gallery__image">
            <div
              v-if="loggedIn && price"
              @click="triggerListModal = true"
              class="product__main__wishlist"
            >
              <IconWishlist />
              <p class="accent">Add to list</p>
            </div>
            <v-img
              :media="{
                alt: null,
                height: 800,
                width: 800,
                id: null,
                position: 1,
                src: `https:${settings.product_fallback_image}`,
              }"
            />
          </div>
        </template>
      </div>
      <div class="product__main__details">
        <div class="product__main__content__wrap">
          <div class="product__main__content">
            <div class="product__main__title">
              <h3>{{ title }}</h3>
            </div>
            <div v-if="loggedIn && price" class="product__main__price">
              <p>{{ money(price) }}<span> (plus GST)</span></p>
            </div>
            <div class="product__main__helpers">
              <div class="product__main__codes">
                <p class="light">Code: {{ data.variants[0].barcode }}</p>
                <p>SKU: {{ data.variants[0].sku }}</p>
              </div>
              <button
                v-if="loggedIn && price"
                @click="triggerListModal = true"
                class="btn__base btn__base--outline-light product__main__wishlist__secondary"
              >
                <IconWishlist />
                <p class="accent">Add to list</p>
              </button>
            </div>
            <div v-if="loggedIn" class="product__main__status">
              <div>
                <product-status :available="data.variants[0].available" />
              </div>
            </div>

            <div class="product__main__description">
              <p v-html="description"></p>
            </div>
            <div class="product__main__atc">
              <product-form
                :variant_id="product.variants[0].id"
                :available="data.variants[0].available"
                :max_quantity="data.variants[0].inventory_quantity"
                :price="price"
                :variant_price="product.variants[0].price"
                :customer="customer"
                :disabled="!loggedIn || !price"
              />
            </div>
          </div>
          <template v-if="Object.keys(data.metafields).length !== 0">
            <hr />
            <div v-if="data.metafields.dimensions" class="product__main__specs">
              <div
                @click="menuStates.specifications = !menuStates.specifications"
              >
                <p class="accent">{{ lang.specifications }}</p>
                <icon-plus v-if="!menuStates.specifications" />
                <icon-minus v-else />
              </div>
              <transition-expand>
                <ul v-if="menuStates.specifications">
                  <li v-if="data.metafields.dimensions[0]">
                    <p class="accent">Length</p>
                    <p class="accent">
                      {{ data.metafields.dimensions[0].value
                      }}{{ data.metafields.dimensions[0].unit }}
                    </p>
                  </li>
                  <li v-if="data.metafields.dimensions[1]">
                    <p class="accent">Width</p>
                    <p class="accent">
                      {{ data.metafields.dimensions[1].value
                      }}{{ data.metafields.dimensions[1].unit }}
                    </p>
                  </li>
                  <li v-if="data.metafields.dimensions[2]">
                    <p class="accent">Height</p>
                    <p class="accent">
                      {{ data.metafields.dimensions[2].value
                      }}{{ data.metafields.dimensions[2].unit }}
                    </p>
                  </li>
                  <li v-if="data.metafields.volume">
                    <p class="accent">{{ lang.volume }}</p>
                    <p class="accent">
                      {{ data.metafields.volume.value
                      }}{{ data.metafields.volume.unit }}
                    </p>
                  </li>
                </ul>
              </transition-expand>
            </div>
            <div
              v-if="template != 'no-safety' && data.metafields.downloads"
              class="product__main__downloads"
            >
              <div @click="menuStates.downloads = !menuStates.downloads">
                <p class="accent">{{ lang.downloads }}</p>
                <icon-plus v-if="!menuStates.downloads" />
                <icon-minus v-else />
              </div>
              <transition-expand>
                <ul v-if="menuStates.downloads">
                  <li
                    v-for="(download, idx) in data.metafields['downloads']"
                    :key="idx"
                  >
                    <a
                      :href="download.url"
                      target="_blank"
                      rel="noopener"
                      download
                      ><icon-pdf />
                      <p class="accent">{{ downloadTitle(download.url) }}</p></a
                    >
                  </li>
                </ul>
              </transition-expand>
            </div>
          </template>
        </div>
      </div>
    </section>
    <section class="product__recommended">
      <product-recommended
        :productId="product.id"
        :title="section.recommended_products_title"
        :customer="customer"
      ></product-recommended>
    </section>
    <template v-if="triggerListModal">
      <product-to-list-modal
        :product="product"
        :price="price"
        :customer="customer"
        :trigger_modal="triggerListModal"
        @close="triggerListModal = false"
        source="product-to-list"
      />
    </template>
  </div>
  <div v-else>
    <loading size="medium" />
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount, onMounted, computed } from 'vue';
import axios from 'axios';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { money } from '../../utils/money';
import Loading from '../render/Loading.vue';
import VImg from './VImg.vue';
import ProductRecommended from './ProductRecommended.vue';
import ProductStatus from './ProductStatus.vue';
import ProductForm from './ProductForm.vue';
import ProductToListModal from './modals/ProductToListModal.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';
import IconPdf from './icons/IconPdf.vue';
import IconPlus from './icons/IconPlus.vue';
import IconMinus from './icons/IconMinus.vue';
import IconWishlist from './icons/IconWishlist.vue';
import IconChevron from './icons/IconChevron.vue';

const props = defineProps({
  product: {
    type: Object,
    required: true,
    default: () => {},
  },
  customer: {
    type: Object,
    required: true,
  },
  section: {
    type: Object,
    required: true,
    default: () => {},
  },
});

const settings = PS.Settings;
const lang = PS.Language.product;

const {
  title,
  vendor,
  available,
  template,
  description,
  excerpt,
  media,
  handle,
  initialVariant,
  hasOneVariant,
  collectionConnection,
} = props.product;

const productCarousel = ref(null);
const triggerListModal = ref(false);

const data = reactive({
  loading: false,
  variants: [],
  metafields: {
    dimensions: null,
    volume: null,
    downloads: null,
  },
});

const menuStates = reactive({
  specifications: false,
  volume: false,
  downloads: false,
});

const productOptions = {
  arrows: false,
  pagination: true,
  type: 'loop',
};

const prevSlide = () => {
  productCarousel.value.go('<');
};
const nextSlide = () => {
  productCarousel.value.go('>');
}

const loggedIn = computed(() => {
  return props.customer.logged_in && props.customer.company ? true : false;
});

const price = computed(() => {
  return data.variants[0].price;
});

const fetchVariants = async () => {
  const config = {
    method: 'get',
    url: `/products/${handle}?view=variants`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await axios(config);
  data.variants = await res.data;
};

const fetchMetafields = async () => {
  data.loading = true;

  const config = {
    method: 'get',
    url: `/products/${handle}?view=metafields`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await axios(config);
  const metafields = await res.data;

  if (Object.keys(metafields).length !== 0) {
    if (metafields.dimensions) {
      data.metafields.dimensions = JSON.parse(metafields.dimensions);
    }
    if (metafields.volume) {
      data.metafields.volume = JSON.parse(metafields.volume);
    }
    if (metafields.downloads) {
      data.metafields.downloads = metafields.downloads;
    }
  }
  data.loading = false;
};

const downloadTitle = (url) => {
  return url
    .substring(url.lastIndexOf('files/') + 6, url.indexOf('.pdf?'))
    .replace(/_/g, ' ');
};

onBeforeMount(() => {
  fetchVariants();
  fetchMetafields();
});
</script>
